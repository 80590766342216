import React from 'react'
import Logo from '../../Logo';
import {
    handleMouseOver,
    handleMouseOutGreen
  } from "../../../shared/sharedFunctions"
import { useNavigate } from 'react-router-dom';
import background from "../../../images/palmtreesdark.png"

export default function PaymentComplete() {

    const navigate = useNavigate()


    return (
      <div
      className="splash-screen d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${background})`,
        minHeight: "100vh"
      }}>
        <div style={{
          height: "100%",
          width: "100%",
          display:"flex", 
          flexDirection:"column",
          justifyContent:"center",
          alignItems:"center"}}>
          <Logo style={{width:"140px", marginBottom:"10px"}}/>
          <h2 style={{ 
            color: "#FFAE46",
            fontWeight:"bold",
            animation: "fadeInUp 1s ease-in-out"
            }}>
              Your Payment Has Been Accepted
          </h2>
          <p style={{ 
            color: "#4D8C53",
            fontWeight:"bold",
            animation: "fadeInUp 1s ease-in-out"
            }}>
              Thank you again for trusting bike911
          </p>
            <button
              id="submit"
              className="btn"
              style={{
                backgroundColor:"#4D8C53",
                animation: "fadeInUp 1s ease-in-out",
                marginTop:"30px",
                color:"white",
                border:"1px solid #4D8C53"
              }}
              onClick={() => {navigate("/")}}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOutGreen}
              >RETURN TO HOME PAGE
            </button>
        </div>
      </div>
          
      );
}
