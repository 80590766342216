import React from 'react'
import {
    handleMouseOut,
    handleMouseOutGreen,
    handleMouseOver,
    successToast,
    failToast
} from '../../../shared/sharedFunctions'

import { createWorkOrderFetch } from '../../../fetchRequests/workOrderFetches';

//importing ability to use cookies
import { useCookies } from "react-cookie";

import { useNavigate } from 'react-router-dom';

export default function SummaryConfirm({
    workOrderSummary,
    returnToStart,
    setLoading
}) {

    console.log(workOrderSummary)

    const navigate = useNavigate()

    //ability to use cookies
    const [cookie, setCookie, removeCookie] = useCookies();


    const {
        user_location,
        work_request,
        work_description,
        work_order_image,
        updateLat,
        updateLng
    } = workOrderSummary

    const createWorkOrder = async () => {
        console.log(
            "WORK ORDER SUMMARY ON CREATE: ",
            workOrderSummary
        )
        setLoading(true)

        const locationSent = {
          updateLat: updateLat,
          updateLng: updateLng,
          service_requested:work_request.description
        }

        //calling function which will send fetch to create a new work order
        const serverResponse = await createWorkOrderFetch(cookie.accessToken, locationSent)

        setLoading(false)

        if(!serverResponse.data){
          failToast(serverResponse.message)
          return
        }

        successToast(serverResponse.message)
        setCookie("work_order_id", serverResponse.data, {path:'/'})
        navigate("/home")
        return

    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{
                color: "#4D8C53",
                fontWeight: "bold",
                animation: "fadeInUp 1s ease-in-out"
            }}>
                Final Summary For New Work Order
            </p>

            <div
                className="form w-100 d-grid gap-3 p-4"
                style={{ maxWidth: "400px", textAlign: "left" }}
            >
                <p style={{ color: "white" }}>
                    <strong style={{ color: "#FFAE46" }}>
                        Your Current Location is:
                    </strong>
                    {" "}{user_location || "Not Confirmed"}
                </p>

                <p style={{ color: "white" }}>
                    <strong style={{ color: "#FFAE46" }}>
                        The Work You Requested Is:
                    </strong>
                    {" "}{work_request.description}
                </p>

                <p style={{ color: "white" }}>
                    <strong style={{ color: "#FFAE46" }}>
                        Description Provided:
                    </strong>
                    {" "}{work_description}
                </p>

                <p style={{ color: "white" }}>
                    <strong style={{ color: "#FFAE46" }}>
                        Image Provided:
                    </strong>
                    {" "}{work_order_image.name}
                </p>

                <button
                    className="btn"
                    style={{
                        backgroundColor: "#4D8C53",
                        color: "white",
                        border: "1px solid black",
                        textShadow: "1px 1px 2px black",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                    }}
                    onClick={() => { createWorkOrder() }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOutGreen}
                    type="button">CREATE WORK ORDER
                </button>
                <button
                    className="btn"
                    style={{
                        backgroundColor: "#FFAE46",
                        color: "white",
                        border: "1px solid black",
                        textShadow: "1px 1px 2px black",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                    }}
                    onClick={() => { returnToStart() }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    type="button">GO BACK
                </button>
            </div>

        </div>
    )
}
