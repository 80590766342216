import React from 'react'

//importing styling boostrap components
import Modal from "react-bootstrap/Modal";

//font awesome icons
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import mechanicPhoto from "../images/services/ebike-mechanic.jpg"

export default function UnderConstructionModal({
    showUnderConstructionModal,
    setShowUnderConstructionModal
}) {
    return (
        <>
          <Modal
            size="sm-8"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showUnderConstructionModal}
            backdrop={true}
            keyboard={false}
            style={{
              maxWidth: "500px",
              maxHeight: "400px",
              top: '50%', 
              left: '50%', 
              transform: "translate(-50%, -50%)",
              position: 'absolute', 
              overflow:"hidden"
            }}
          >
          <>
            <Modal.Header className="modalHeader">
              <div className="confirmModalNameContainer">
                <Modal.Title 
                className="poppins-extrabold" 
                style={{
                    color:"#FFAE46", 
                    fontSize:"1.5rem", 
                    fontWeight:"bold",
                    textAlign:"center"}}>
                    UNDER CONSTRUCTION
                </Modal.Title>
              </div>
                <FontAwesomeIcon
                  className="modalCloseButton"
                  icon={faTimes}
                  color="grey"
                  onClick={() => {
                    setShowUnderConstructionModal(false)
                  }}
                />
            </Modal.Header>
              <Modal.Body className="modalBody">

                

                <div style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    alignItems: "center",
                    width: "100%",
                    scrollbarWidth: "none",
                    '-ms-overflow-style': 'none' }}> 

                    <img 
                        src={mechanicPhoto} 
                        alt="Bike tools" 
                        style={{ 
                            borderRadius: "10px", 
                            maxHeight:"180px"
                        }} 
                    />

                    <hr style={{ 
                        width: "80%", 
                        margin: "10px auto", 
                        borderTop: "3px solid white", 
                        marginBottom:"20px"
                    }} />    

                    <p style={{
                        color: "#4D8C53", 
                        textAlign:"center",
                        fontSize:"1.25rem"}}>
                        This feature is currently under construction and not yet avaliable for use
                    </p>
        
                </div>
              </Modal.Body>
            </>
          </Modal>
        </>
      );
}
