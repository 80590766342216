import React, {useState} from 'react'
import baseBikeIcon from "../../../images/bike-icon.png";
import { handleMouseOver,handleMouseOut,handleMouseOutGreen } from '../../../shared/sharedFunctions';
import BikeEditModal from './BikeEditModal';
import UnderConstructionModal from '../../UnderConstructionModal';

export default function BikeColumn({userData,isMobile}) {

    const [showBikeEditModal,setShowBikeEditModal] = useState(false)

    const [showUnderConstructionModal,setShowUnderConstructionModal] = useState(false)

    const [bikeData,setBikeData] = useState({
        model:"",
        brand:"",
        serial_number:"",
        date_added:"",
        tire_size:"",
        tube_size:"",
        bike_picture:""
    })

    const getBikeDataFetch = async () => {

    }

  return (
    <div style={{
        flexDirection: "column",
        marginLeft: isMobile ? "0px" : "15px",
        marginBottom:"30px"}}>

        <BikeEditModal showBikeEditModal={showBikeEditModal} setShowBikeEditModal={setShowBikeEditModal}/>
        
        <UnderConstructionModal
          showUnderConstructionModal={showUnderConstructionModal}
          setShowUnderConstructionModal={setShowUnderConstructionModal}
        />

        {userData.bike.picture ? (
                <img
                    src={userData.bike.picture}
                    style={{
                        borderRadius: "50%",
                        height: "250px", // Set height and width to the same value
                        width: "250px",
                        border: "1px solid #FFAE46",
                        objectFit: "cover" // Ensures image maintains aspect ratio and covers the square
                    }}  
                    alt="user icon"
                />
            ) : (
                <img
                    src={baseBikeIcon}
                    style={{
                    borderRadius: "50%",
                    height: "250px", // Set height and width to the same value
                    width: "250px",
                    border: "1px solid #FFAE46",
                    objectFit: "cover"
                    }} 
                    alt="user icon"
                /> 
        )}

        <div className="accountUserInfoBottomText poppins-regular">
            Your Bike {bikeData.serial_number}
        </div>

        <button
            onClick={() => {setShowBikeEditModal(true)
            }}
            className="btn"
            style={{
                backgroundColor:"#4D8C53", 
                color:"white",
                width:"100%", 
                marginBottom:"5px", 
                marginTop:"5px",
                border:"1px solid #4D8C53"
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOutGreen}
        >
            Edit Bike Information
        </button>

        <div className="accountUserInformationContainer">
            

            <div className="accountUserInformationContainer">
                <div className="accountUserInfoSubText poppins-extralight">
                    Brand
                </div>
                <div className="accountUserInfoText poppins-regular">
                    {userData.bike.brand}
                </div>
                <div className="accountUserInfoSubText poppins-extralight">
                    Model
                </div>
                <div className="accountUserInfoText poppins-regular">
                    {userData.bike.model}
                </div>
                <div className="accountUserInfoSubText poppins-extralight">
                    Tire Size
                </div>
                <div className="accountUserInfoText poppins-regular">
                    {/* {userData.bike.model} */}
                    000-000-000
                </div>
                <div className="accountUserInfoSubText poppins-extralight">
                    Tube Size
                </div>
                <div className="accountUserInfoText poppins-regular">
                    {/* {userData.bike.model} */}
                    00/00/0000
                </div>
            </div>
          
        </div>

        <button
            onClick={() => {setShowUnderConstructionModal(true)
            }}
            className="btn"
            style={{
                backgroundColor:"#FFAE46", 
                width:"100%", 
                marginBottom:"5px", 
                marginTop:"5px", 
                color:"white",
                border:"1px solid #FFAE46"
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            Change Active Bike
        </button>

    </div>
  )
}
