//importing axios to make fetch request
import axios from "axios";

//importing fetch requests to create normal and error logs
import { 
  createLog,
  createErrorLog
} from "../shared/loggingFunctions";

//setting the base endpoint to send fetch api's too
const API_URL = 'https://bike911-server.onrender.com/user';



//===================================GETTING USERS INFORMATION===================================//

//------FUNCTIONAL------//
export const retrieveUserInformation = async (accessToken) => {

  //data for api logging
  const endpoint = "/user/user-information"
  const method = "GET"

  //setting headers of fetch request to include access token
  const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };
  
  try{

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
    })

    //send fetch request
    const requestSent = await axios.get(`${API_URL}/user-information`, {headers})

    //if checking for succesful response
    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.data.data,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      //returning server response data
      return serverResponse
    }

  }catch(error){

    //if ensuring proper error data was recieved
    if (error.response && error.response.data) {
      const message = error.response.data.message
      const serverResponse = {
          message:message
      }

      //CREATE ERROR LOG//
      await createErrorLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH ERROR",
        error_data:serverResponse,
      })

      return serverResponse
    } 
  }
}

//===================================GETTING USERS INFORMATION===================================//

//------FUNCTIONAL------//
export const updateUserInformation = async (accessToken, updateUserForm) => {

  const formData = new FormData();

  // Append text fields
  formData.append('name', updateUserForm.name);
  formData.append('email', updateUserForm.email);
  formData.append('phone', updateUserForm.phone);
  formData.append('brand', updateUserForm.brand);
  formData.append('model', updateUserForm.model);
  formData.append('user_name', updateUserForm.user_name);

  // Append the image file with the exact field name expected by multer
  if (updateUserForm.profile_picture) {
    formData.append('profile_picture', updateUserForm.profile_picture);
  }


  //data for api logging
  const endpoint = "/user/user-update"
  const method = "POST"

  //setting headers of the fetch request
  const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
    };

  try{ 

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_body:formData
    })

    //sending fetch request
    const requestSent = await axios.post(`${API_URL}/user-update`,formData, {headers})

    //if statement checking for succesful response
    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.status,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      //returning data
      return serverResponse
    }

  }catch(error){
    //if statement checking for proper error data
    if (error.response && error.response.data) {
        const message = error.response.data.message
        const serverResponse = {
            message:message
        }

        //CREATE ERROR LOG//
        await createErrorLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH ERROR",
          error_data:serverResponse,
        })
        
        return serverResponse
      } 
  }
  }
