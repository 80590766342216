// import './App.css';
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

//importing css and bootsrap styling
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../src/stylesheets/main.scss';
import './App.css'



//COMPONENTS
import Home from './components/CustomerOnlySection/HomePageComponents/Home';
import AccountPage from './components/CustomerOnlySection/AccountPageComponents/AccountPage.jsx';
import PaymentComponent from "./components/CustomerOnlySection/PaymentComponents/PaymentComponent.jsx";
import PaymentComplete from "./components/CustomerOnlySection/PaymentComponents/PaymentComplete.jsx";
import NewWorkOrder from "./components/CustomerOnlySection/NewWorkOrderComponents/NewWorkOrder.jsx";


import Login from './components/PublicFacingSection/LoginComponents/Login';
import Register from './components/PublicFacingSection/LoginComponents/Register';
import RegisterCode from './components/PublicFacingSection/LoginComponents/RegisterCode';
import Services from "./components/PublicFacingSection/ServicesComponenets/Services.jsx"
import Landing from "./components/PublicFacingSection/LandingPageComponents/Landing.jsx";
import FAQPage from "./components/PublicFacingSection/FAQComponents/FAQPage.jsx";

//importing ability to use cookies
import { useCookies } from "react-cookie";


//importing fetch request to verify user has logged in
import { fetchLoginVerify } from "./fetchRequests/tokenFetch";
//importing fetch to create initial log session
import { initializeLoggerFetch } from "./fetchRequests/loggingFetch.js";

//importing toastify container and styling
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//importing shared styling functions
import {
  failToast,
  successToast,
} from "./shared/sharedFunctions"

//importing shared get user location function
import { getLocation } from "./shared/locationFunctions.js";

//importing react google analytics
import ReactGA from 'react-ga4';

function App() {

  //initializing react google analytics
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

  //ability to navigate
  const navigate = useNavigate();

  //detecting users location in website
  const location = useLocation();

  //ability to use cookies
  const [cookie, setCookie, removeCookie] = useCookies();

  //parsing browser cookies
  const token = cookie.accessToken
  const sessionId = cookie.session_token

  //use state containing work order data which is passed as a prop
  //to all other components
  const [workOrderData, setWorkOrderData] = useState({
    direction_line: null,
    travel_time: null,
    distance: null,
    mechanic_name: null,
    mechanic_email: null,
    mechanic_location: null,
    mechanic_phone: null
  })

  //use state holding the users location generated by the google watch location function
  const [usersUpdatedLocation, setUsersUpdatedLocation] = useState(null);
  //use state to hold users most up to date location
  const [userLocation, setUserLocation] = useState(null)

  // -------------------- LOCATIONS FOR TESTING--------------------//

  // //MY HOUSE
  // const [usersUpdatedLocation, setUsersUpdatedLocation] = useState({lat: 28.2423174, lng: -82.7001013});
  // const [userLocation, setUserLocation] = useState({lat: 28.2423174, lng: -82.7001013})

  // //ST PETE OFFICE
  // const [usersUpdatedLocation, setUsersUpdatedLocation] = useState({lat: 27.7730937, lng: -82.6387506});
  // const [userLocation, setUserLocation] = useState({lat: 27.7730937, lng: -82.6387506})

  //===================================GETTING USER LOCATION===================================//

  const getUsersLocation = async () => {

    //calling get location function to get users current location
    const userCoords = await getLocation()

    // console.log("location: ", userCoords)

    if (!userCoords) {
      console.log("innacurate location: ", userCoords)

      return
    }

    setUserLocation({
      lat: userCoords.lat,
      lng: userCoords.lng
    })

    console.log("accurate location: ", userCoords)
    return

  }

  //use effect to update users location every 15 seconds
  useEffect(() => {

    let interval

    interval = setInterval(() => {
      if (!userLocation) {
        getUsersLocation()
      }
    }, 5000); // 15 seconds in milliseconds
    return () => clearInterval(interval);

  }, [userLocation])


  //===================================WATCHING USER LOCATION===================================//

  //geolocation options for the watch position function
  const geolocationOptions = {
    enableHighAccuracy: true,
    maximumAge: 25,
    timeout: 10000,
    wifi: true,
    accuracy: 50
  };

  //function used to update the user location should it change
  if ("geolocation" in navigator) {
    navigator.geolocation.watchPosition(success, error, geolocationOptions);
  } else {
    failToast("Geolocation not supported");
  }
  function success(position) {
    if (position.coords.accuracy <= 50) {
      setUsersUpdatedLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      console.log("Users current location retrieved succesfully")
    } else {
      console.log("User current location was not able to be accurately retrieved")
    }
  }
  function error() {
    console.log("Error getting users updated location");
  }

  //===================================VERIFYING LOGIN TOKEN===================================//

  const verifyLogin = async () => {

    //sending fetch to confirm users login token is authentic
    const serverResponse = await fetchLoginVerify(token);

    if (!serverResponse.data) {
      navigate("/")
      failToast(serverResponse.message)
    }

  }

  //use effect calling to verify login anytime the user navigates away from
  //the login components
  useEffect(() => {
    if (location.pathname === "/" ||
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/register-code" ||
      location.pathname === "/faq" ||
      location.pathname === "/services") {
      return
    }

    if (token) {
      verifyLogin()
      return
    }

    navigate("/")

  }, [token, location.pathname, navigate])


  //===================================INITIALIZE LOGGER===================================//

  //initializing the session log on website load
  const getLoggerSession = async () => {
    const requestSent = await initializeLoggerFetch()

    //setting an experation for the session token
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 86400 * 1000); // 86400 seconds * 1000 milliseconds


    setCookie('session_token', requestSent.data, {
      path: '/',
      expires: expirationDate,
      secure: true,
      sameSite: 'strict',
    });

    return
  }

  //use effect which calls to initialize the logging session
  //as long as there is not an existing session
  useEffect(() => {
    if (!sessionId) {
      getLoggerSession()
    }
  }, [])


  //===================================HANDLING WEBSITE CLOSE===================================//

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Perform cleanup or specific actions before page is unloaded or refreshed
  //     console.log('Performing cleanup before unloading or refreshing');
  //     // Example: Prompt user with a confirmation dialog if needed
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   const handleUnload = () => {
  //     // Perform actions that should only occur when the page is closed, not refreshed
  //     console.log('Page is being unloaded');
  //     // removeCookie("session_token");
  //     // Example: Additional cleanup or final actions
  //   };

  //   // Add event listeners
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener('unload', handleUnload);

  //   // Cleanup: Remove event listeners on component unmount
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);

  //===================================JSX RETURN===================================//

  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/faq" element={<FAQPage />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/register-code" element={<RegisterCode />} />

        <Route exact path="/home" element={<Home
          workOrderData={workOrderData}
          setWorkOrderData={setWorkOrderData}
          userLocation={userLocation}
          usersUpdatedLocation={usersUpdatedLocation} />} />
        <Route exact path="/account-page" element={<AccountPage
          workOrderData={workOrderData}
        />} />

        <Route exact path="/payment" element={<PaymentComponent />} />
        <Route exact path="/payment-complete" element={<PaymentComplete />} />

        <Route exact path="/new-work-order"
          element={
            <NewWorkOrder
              usersUpdatedLocation={usersUpdatedLocation}
            />
          } 
        />

      </Routes>
    </div>
  );
}

export default App;