import React from "react";

//importing styling boostrap components
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';

//universal loading modal
export default function LoadingModal({message}) {


  //===================================JSX RETURN===================================//

  return (
    <>
      <Modal
        // size="sm-8"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        backdrop={false}
        keyboard={false}
        style={{
          maxWidth: "400px",
          maxHeight: "300px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 900
        }}
      >
        <div 
          className="modal-content" 
        >
          
          <Modal.Header className="modalHeader">
                    <div style={{textAlign:"center"}}>
                        <Modal.Title className="poppins-extrabold" style={{color:"#FFAE46"}}>
                            LOADING
                        </Modal.Title>
                    </div>
          </Modal.Header>

          <Modal.Body style={{
                  alignItems:"center",
                  justifyContent:"center",
                  textAlign:"center",
                  marginTop:"10px",
                  marginBottom:"10px"}}>
                  <Spinner animation="border" role="status"/>
                  <div className="poppins-bold"
                    style={{marginTop:"10px",color:"white", fontWeight:"bold"}}>
                      {message}
                  </div>
          </Modal.Body>   

        </div>   
      </Modal>
    </>
  );
}
