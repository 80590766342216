import React , { useState, useEffect } from "react";

//importing react boostrap components
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';

//font awesome icons
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//images used in the component
import { useCookies } from "react-cookie";

//importing fetch to send user confirming mechanic service to server
import { sendUserConfirmService } from "../../../fetchRequests/workOrderFetches";

//importing shared styling functions
import { 
  failToast,
   successToast,
   handleMouseOver,
   handleMouseOut,
   handleMouseOutGreen
   } from "../../../shared/sharedFunctions";


export default function ConfirmServiceModal({
  workOrderData,
  showConfirmOrderModal,
  confirmOrder,
  cancelWorkOrder
}) {
  
  //ability to use cookies
  const [cookie, setCookie, removeCookie] = useCookies();

  const [searchTimeout, setSearchTimeout] = useState(false)

   //use effect that will set the timeout to true,
   //displaying Brents information should the mechanic take too long
   //to respond
  useEffect(() => {
    console.log("timer with use effect triggered in confirm service modal")
    let interval
    interval = setInterval(() => {
      if(showConfirmOrderModal){
        setSearchTimeout(true)
      }
    }, 20000); // 10 seconds in milliseconds
      return () => clearInterval(interval);
  }, [showConfirmOrderModal]);

  useEffect(() => {
    console.log("searchTimeout in confirm service modal", searchTimeout)
    console.log("showConfirmModal in confirm service modal", showConfirmOrderModal)
  }, [searchTimeout, showConfirmOrderModal])


  const userConfirmService = async () => {

    //calling function which will send fetch to server marking work order as accepted
    const serverResponse = await sendUserConfirmService(cookie.accessToken, cookie.work_order_id)

    if(!serverResponse.data){
      failToast(serverResponse.message)
      return
    }

    successToast(serverResponse.message)
    confirmOrder()
    return
  }


  //===================================JSX RETURN===================================//

  return (
    <>
      <Modal
        size="sm-8"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirmOrderModal}
        backdrop={false}
        keyboard={false}
        style={{
          maxWidth: "500px",
          maxHeight: "400px",
          top: '0', 
          left: '0', 
          position: 'fixed', 
          marginTop: '90px',
          marginLeft:"50px",
          overflow:"hidden"
        }}
      >
      { workOrderData.mechanic_name ? (
      <>
        <Modal.Header className="modalHeader">
          <div className="confirmModalNameContainer">
            <Modal.Title className="poppins-extrabold" style={{color:"#FFAE46"}}>
              {workOrderData.mechanic_name}
            </Modal.Title>
          </div>
          {/* canceling service order if modal is closed */}
          <div className="confirmModalCloseContainer">
            <FontAwesomeIcon
              className="modalCloseButton"
              icon={faTimes}
              color="grey"
              onClick={() => {
                cancelWorkOrder()
              }}
            />
          </div>
        </Modal.Header>
          <Modal.Body className="modalBody">
            <div className="modalBodyRows">
              <div className="confirmModalBodyLeftColumn poppins-regular" style={{color:"white"}}>
                <strong style={{color:"#4D8C53"}}>Phone:</strong> {" "}{workOrderData.mechanic_phone}
              </div>
              <div className="confirmModalBodyLeftColumn" style={{color:"white"}}>
              <strong style={{color:"#4D8C53"}}>Email:</strong>{" "} {workOrderData.mechanic_email}
              </div>
            </div>
            <div className="modalBodyRows">
              <div className="confirmModalBodyRightColumn poppins-regular" style={{color:"white"}}>
              <strong style={{color:"#4D8C53"}}>Distance:</strong>{" "} {workOrderData.distance}
              </div>
              <div className="confirmModalBodyRightColumn poppins-medium" style={{color:"white"}}>
              <strong style={{color:"#4D8C53"}}>Time:</strong>{" "} {workOrderData.travel_time}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modalFooter">
            <button
              className="btn"
              style={{
                backgroundColor:"#4D8C53",
                border:"1px solid #4D8C53",
                color:"white"
              }}
              onClick={() => {
                userConfirmService();
                // setShowConfirmOrderModal(false);
              }}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOutGreen}
            >
              Confirm Service Call
            </button>
          </Modal.Footer>
        </>

        ) : (

          <Modal.Body>
                <div style={{
                  alignItems:"center",
                  justifyContent:"center",
                  textAlign:"center",
                  marginTop:"10px",
                  marginBottom:"10px"
                  }}>
                  
                  <div className="poppins-bold"
                    style={{marginTop:"10px",color:"#FFAE46"}}>
                    <Spinner 
                      animation="border" 
                      role="status" 
                      style={{
                        width:"16px",
                        height:"16px",
                        marginRight:"5px",
                      }}
                    />
                    Searching for mechanic...
                  </div>
                  {searchTimeout && (
                    <div 
                      className="poppins-extralight"
                      style={{
                        marginTop:"10px",
                        color:"#4D8C53"
                      }}
                    >
                      Search is taking longer than expected.
                      Please try making work order again or contact us directly
                      here: <strong>727-565-8775</strong>
                    </div>
                  )}
                  <button
                        className="btn btn-primary"
                        style={{marginTop:"10px"}}
                        onClick={() => {
                          cancelWorkOrder()
                        }}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        type="button">Cancel
                    </button>
                </div>
          </Modal.Body>      

        )}
      </Modal>
    </>
  );
}
