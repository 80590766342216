import React from 'react'
import HazardSign from "../../images/under-construction/hazard-sign.png"

export default function ConstructionBanner() {

 

  return (
    <span style={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        height: '40px',
        margin: '0 auto',
        backgroundColor:'rgba(0, 0, 0, 0.5)',
        position: "absolute",
        left: "0",
        top: "0",
        marginTop:"110px",
        zIndex:100
    }}>

        {/* Background image */}
        <img 
        src={HazardSign} 
        style={{ height: '35px'}}
        alt='construction hazard sign' />
        
        {/* Text overlay */}
        <div style={{
            color: 'white',
            fontSize: '15px',
            fontWeight: 'bold',
            textAlign: 'center',
        }}>
            Website is under construction all content is subject to change
        </div>

        {/* Background image */}
        <img 
        src={HazardSign} 
        style={{ height: '35px'}}
        alt='construction hazard sign' />
        
    </span>
  )
}
