//importing react tools
import React, { useEffect, useState } from "react";

//importing ability to navigate
import { useNavigate } from "react-router";

//importing child components
import ConfirmServiceModal from "../Modals/ConfirmServiceModal";
import HelpButton from "./HelpButton";
import OrderDetailsPanel from "./OrderDetailsPanel";
import LoadingModal from "../Modals/LoadingModal.jsx";
import WorkDetailsModal from "../Modals/WorkDetailsModal.jsx";
import MechanicArriveModal from "../Modals/MechanicArriveModal.jsx";
import GoogleMapComponent from "./GoogleMapComponent.jsx";


//importing ability to use cookies
import { useCookies } from "react-cookie";

//importing fetch requests functions for map
import {
  mechanicsForMap,
  callForCenter 
} from "../../../fetchRequests/mapFetchs.js"

//importing fetch requests functions for work orders
import { 
  workOrderDelete,
  getUpdatedWorkOrder 
} from "../../../fetchRequests/workOrderFetches.js";

//importing shared styling functions
import {
  failToast,
  successToast
} from "../../../shared/sharedFunctions.js"

//importing function to get users current location
import { getLocation } from "../../../shared/locationFunctions.js";


//Main function to contain map component and perform fetch requests
const Map = ({ 
  workOrderData, 
  setWorkOrderData, 
  userLocation,
  usersUpdatedLocation}) => {

  //use states to determine wether to show or hide modals
  const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false)
  const [showWorkDetailsModal, setShowWorkDetailsModal] = useState(false)
  const [showMechanicArriveModal, setShowMechanicArriveModal] = useState(false)
  const [loadingFetchOrder, setLoadingFetchOrder] = useState(false)


  //use states determing component activity depending on current state of work order
  const [activeWorkOrder, setActiveWorkOrder] = useState(false)
  const [orderInProgress, setOrderInProgress] = useState(false)

  //use state that holds the direction polyline drawn on map
  const [existingPolyline, setExistingPolyline] = useState(null);
  
  //use state holding mechanic details to display
  const [mechanics, setMechanics] = useState(null)
  
  //use state holding map instance to add icons and polylines
  const [mapInstance, setMapInstance] = useState(null);

  //ability to navigate
  const navigate = useNavigate();

  //initializing cookies and parsing existing ones in browser
  const [cookie, setCookie, removeCookie] = useCookies();
  const workOrderToken = cookie.work_order_id
  const accessToken = cookie.accessToken

  //===================================RENDERING REPAIRMEN MARKERS===================================//
  
  //adding the repairmens markers to the map
  const mapRepairmen = async () => {

    if(usersUpdatedLocation){

      // const userLocation = await getUserLocation()
      
        //calling function to get mechanics that will be displayed on map
        const nearbyMechanics = await mechanicsForMap(accessToken, usersUpdatedLocation)
        
        if(!nearbyMechanics.data){
          failToast(nearbyMechanics.message)
          // navigate("/")
          return
        }
  
        const retrievedMechanics = nearbyMechanics.data
  
        setMechanics(retrievedMechanics)
    }
  };

  //use effect which will call the map repairmen function on page load
  const [pageLoadCount, setPageLoadCount] = useState(0)
  useEffect(() => {
    if(usersUpdatedLocation){
      if(pageLoadCount === 0){
        mapRepairmen()
        setPageLoadCount(pageLoadCount + 1)
      }
    }
  }, [usersUpdatedLocation])

  //use effect that will call to map repairmen on a timer
  //as long as there is not an active work order
  useEffect(() => {
    let interval
    interval = setInterval(() => {
      if(!activeWorkOrder && !showMechanicArriveModal){
        if(usersUpdatedLocation){
          mapRepairmen();
        }
      }
    }, 10000); // 10 seconds in milliseconds
      return () => clearInterval(interval);
  }, [activeWorkOrder, usersUpdatedLocation]);


  //===================================UPDATING AND GETTING UPDATED WORK ORDER===================================//

  const updatedWorkOrder = async () => {

    const sendWorkOrderData = async () => {

      //getting users current location
      const userAccurateLocation = await getLocation()

      //if statement using users last known location if there most recent location data cannot be retreived
      if(!userAccurateLocation){
        return await getUpdatedWorkOrder(accessToken, workOrderToken, usersUpdatedLocation)
      }

      return await getUpdatedWorkOrder(accessToken, workOrderToken, userAccurateLocation)
    }

    //calling function to send fetch that updates work order
    const workOrderData = await sendWorkOrderData()

    setLoadingFetchOrder(false)
    // const workOrderFromServer = workOrderData.updated_work_order

    //if confirming there is no active work order
    if(workOrderData.time_stamp){
      return
    }

    //if catching errors
    if(!workOrderData.data){
      failToast(workOrderData.message)
      //navigate("/")
      return
    }

    //parsing resonse data
    const responseData = workOrderData.data.updated_work_order

    //if determining behavior if no mechanic is attached to work order
    if (responseData.mechanic_id === null) {

      //map repairmen if no repairmen has accepted
      await mapRepairmen()

      if(responseData.status !== "complete" && responseData.status !== "on-site"){
        helpCallMade()
        setCookie("work_order_id", responseData._id, {path:'/'})
        return
      }
    } 
    
    //if to determin behavior if work order has been accepted by mecanic
    if (responseData.status === "accepted") {
      mechanicConfirm(responseData)
      setCookie("work_order_id", responseData._id, {path:'/'})
      return
    }


    //if determining behavior if work order is currently in progress
    if(responseData.status === "in progress"){
      setOrderInProgress(true)
      setActiveWorkOrder(true)

      destructureServerResponse(responseData)

      setCookie("work_order_id", responseData._id, {path:'/'})

        // uzzpdateWorkOrderDiplayData(responseData)
        return
      }


    //if determining behavior if mechanic has arrived on site
    if(responseData.status === "on-site"){

      destructureServerResponse(responseData)

      // uzzpdateWorkOrderDiplayData(responseData)
        setOrderInProgress(true)


        mechanicArriveOnSite()

        setCookie("work_order_id", responseData._id, {path:'/'})

        return
    }  

    //if determining behavior is work order is marked as complete
    if(responseData.status === "complete"){

      destructureServerResponse(responseData)

      mechanicArriveOnSite()

      setCookie("work_order_id", responseData._id, {path:'/'})
    } 

  }

  //use effect to check for work order on page load
   useEffect(() => {
    setLoadingFetchOrder(true)
    updatedWorkOrder()
   }, [])

  //use effect to call for work order details as soon as
  //user confirms service
  useEffect(() => {
    if(orderInProgress){
      updatedWorkOrder()
    }
  }, [orderInProgress])

  //use effect to call the update work order
  //function on a timer as long as there is an active work order
  useEffect(() => {
    let interval
    interval = setInterval(() => {
      if(activeWorkOrder && !showMechanicArriveModal){
      updatedWorkOrder();
      }
    }, 10000); // 10 seconds in milliseconds
      return () => clearInterval(interval);
  }, [activeWorkOrder]);


  //===================================CENTERING VIEW AFTER SERVICE CALL===================================//

  //function to recenter the view after service call is made
  const centerMapOnPath = async () => {
    const google = window.google;

    //calling function to call fetch that gets data necessary to center map view
    const centerParams = await callForCenter(accessToken, workOrderToken);

    //parsing data
    const center = new google.maps.LatLng(centerParams.data.center.lat, centerParams.data.center.lng);

    //centering map view
    mapInstance.setCenter(center);
    mapInstance.setZoom(centerParams.data.zoom);
  };

  //===================================CANCELING WORK ORDER===================================//

  const cancelWorkOrder = async () => {

    //calling function which actives fetch to delete work order
    const workOrderCancel = await workOrderDelete(accessToken, workOrderToken)

    //if statment incase work order cannot be deleted
    if(!workOrderCancel.data){
      failToast(workOrderCancel.message)
      //navigate("/")
      return
    }

    successToast(workOrderCancel.message)

    //setting all work order use states back to null
    setShowConfirmOrderModal(false);
    setShowWorkDetailsModal(false)
    setWorkOrderData({ 
      direction_line:null,
      travel_time:null,
      distance:null,
      mechanic_name:null,
      mechanic_email:null,
      mechanic_location:null,
      mechanic_phone:null
    });
    if (existingPolyline) {
      setExistingPolyline(null);
    }
    setActiveWorkOrder(false)
    removeCookie("work_order_id");
  }

  //===================================START AND END SERVICE FUNCTIONS===================================//

  //function determining behavior once
  //user has submitted a new work order
  const helpCallMade = () => {
    setShowWorkDetailsModal(false)
    setShowConfirmOrderModal(true)
    setActiveWorkOrder(true)
  }
  
  //function determining behavior once a mechanic has accepted the work order
  const mechanicConfirm = (workOrderFromServer) => {

    setWorkOrderData({
      travel_time: workOrderFromServer.time ,
      distance: workOrderFromServer.distance,
      mechanic_name: workOrderFromServer.mechanic_info.name,

      mechanic_phone: workOrderFromServer.mechanic_info.phone,
      mechanic_email: workOrderFromServer.mechanic_info.email,

      direction_line: workOrderFromServer.directions ,
      mechanic_location:{
        lat: workOrderFromServer.mechanic_info.lat,
        lng: workOrderFromServer.mechanic_info.lng
      }
    });
    setExistingPolyline(workOrderFromServer.directions);
    setMechanics([{
      email:workOrderFromServer.mechanic_info.email,
      location:{
        lat: workOrderFromServer.mechanic_info.lat,
        lng: workOrderFromServer.mechanic_info.lng
      }
    }])
    setShowConfirmOrderModal(true)
  }
  

  //function determining behavior once
  //user can confirmed mechanic accepting work order
  const confirmOrder = () => {
    
    setOrderInProgress(true)
    centerMapOnPath()
    displayDirections(workOrderData)
    setShowConfirmOrderModal(false);
  };
  
  

  //ending servie and showing the appropriate modal
  const mechanicArriveOnSite = () => {
    if (existingPolyline) {
      setExistingPolyline(null);
    }
    setShowConfirmOrderModal(false)
    setShowMechanicArriveModal(true)
    return
  };


  //function to reset all data used in simulation to base after order is complete
  const endOrder = () => {

    //setting all the use states back 
    //to null once work order has been completed
    setActiveWorkOrder(false);
    setOrderInProgress(false)
    setWorkOrderData({
      direction_line:null,
      travel_time:null,
      distance:null,
      mechanic_name:null,
      mechanic_email:null,
      mechanic_location:null,
      mechanic_phone:null
    })
    if (existingPolyline) {
      setExistingPolyline(null);
    }
  };


  //===================================DISPLAYING DIRECTION DATA===================================//

  //destructuring response from server to display data
  const destructureServerResponse = (responseData) => {

    const updatedOrderDetails = {
      direction_line:responseData.directions,
      travel_time:responseData.time,
      distance:responseData.distance,
      mechanic_name:responseData.mechanic_info.name,
      mechanic_email:responseData.mechanic_info.email,
      mechanic_phone:responseData.mechanic_info.phone,
      mechanic_location:{
        lat:responseData.mechanic_info.lat,
        lng:responseData.mechanic_info.lng
      }
    }

    displayDirections(updatedOrderDetails)
  }



  //display the now parsed work order data to user
  const displayDirections = (directionDetails) => {
    
    setExistingPolyline(existingPolyline => {
      if (existingPolyline) {
        return null;
      }
      return existingPolyline;
    });   

    setWorkOrderData({
      travel_time: directionDetails.travel_time,
      distance: directionDetails.distance,
      mechanic_name: directionDetails.mechanic_name,
      mechanic_email:directionDetails.mechanic_email,
      mechanic_phone: directionDetails.mechanic_phone
    });

    setExistingPolyline(directionDetails.direction_line);
    
    setMechanics([{
      email:directionDetails.mechanic_email,
      location:directionDetails.mechanic_location,
    }])
  }

  //===================================JSX RENDERING MAP===================================//

  return (
    <div className="map">
      {usersUpdatedLocation && userLocation ? (
        <div>
            <GoogleMapComponent 
              usersUpdatedLocation={usersUpdatedLocation}
              existingPolyline={existingPolyline}
              mechanics={mechanics}
              setMapInstance={setMapInstance}
              userLocation={userLocation}
              />
          {!showMechanicArriveModal && !orderInProgress && !loadingFetchOrder &&(
              <HelpButton
              activeWorkOrder={activeWorkOrder}
              cancelWorkOrder={cancelWorkOrder}
              ></HelpButton>
          )}

          {loadingFetchOrder && (
            <LoadingModal message={"Checking for existing work order..."}/>
          )}
        </div>
        ) : (
          <LoadingModal message={"Retrieving location data..."}/>
      )}
        
        {activeWorkOrder && !showMechanicArriveModal && !showConfirmOrderModal &&(
          <OrderDetailsPanel workOrderData={workOrderData}></OrderDetailsPanel>
        )}

        <ConfirmServiceModal
        workOrderData={workOrderData}
        showConfirmOrderModal={showConfirmOrderModal}
        confirmOrder={confirmOrder}
        cancelWorkOrder={cancelWorkOrder}
        />

        <WorkDetailsModal
          showWorkDetailsModal={showWorkDetailsModal}
          setShowWorkDetailsModal={setShowWorkDetailsModal}
          usersUpdatedLocation={usersUpdatedLocation}
          helpCallMade={helpCallMade}
        />

        <MechanicArriveModal
          showMechanicArriveModal={showMechanicArriveModal}
          setShowMechanicArriveModal={setShowMechanicArriveModal}
          workOrderData={workOrderData}
          endOrder={endOrder}
        />

    </div>
  );
};

export default Map;