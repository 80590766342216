



export const FAQData = [
    {
        question:"Where do you currently operate",
        answer:"Currently we operate in the greater St.Petersburg area"
    },
    {
        question:"What are your hours of operation",
        answer:"Tuesday to Saturday 10AM - 6PM"
    },
    {
        question:"How will I know when my mechanic has arrived",
        answer:"When your mechanic arrives to you the phone number registered to your account should recieve a text messages"
    },
    {
        question:"What all bike services do you offer",
        answer:"We have a full breakdown of all our offerings, along with their prices, on our services page which can be found from the services button in the header"
    },
    {
        question:"Why is the map not showing my position",
        answer:"If you are encountering any errors with your map please confirm that your web browser is allowed to use your location while the website is open"
    },
    {
        question:"What perks come from a membership",
        answer:"Members get 50% off the cost of all service calls"
    },
    
]