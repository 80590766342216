//importing react tools
import React, {useState} from 'react'

//Bootstrap tool for styling divs
import Container from 'react-bootstrap/Container';
//Bootstrap tool used for creating nav bar components
import Nav from 'react-bootstrap/Nav';
//Boostrap tool used to create top of page navigation bars
import Navbar from 'react-bootstrap/Navbar';

//importing ability to navigate
import { useNavigate } from "react-router";

//importing company logo
import Logo from '../Logo';

import ConstructionBanner from "./ConstructionBanner";

export default function PublicHeader() {
    const [showPanel, setShowPanel] = useState(false)
    const navigate = useNavigate();

    //function to recolor buttons on mouse over
    const handleMouseOver = (e) => {
        e.target.style.color = '#4D8C53';
    };

    //function used to recolor button on mouse out
    const handleMouseOut = (e) => {
        e.target.style.color = '#FFAE46';
    };


  return (
    <>
    {['md'].map((expand) => (
        <Navbar 
            key={expand} 
            expand={expand} 
            bg="clear" 
            data-bs-theme="dark" 
            className="fixed-top pb-4" 
            style={{ 
                zIndex: 1000, 
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                height:"110px"
                }}>
            <Container fluid>
                {/*  The brand containes the logo and seems to act as a container that reacts to the size of the screen */}
                <Navbar.Brand href="#" className="w-100 d-flex justify-content-center justify-content-sm-start align-items-center ">
                    <Logo style={{width: '100px'}}/>
                </Navbar.Brand>
                {/* this toggle only displays when there is not enough space for the Nav.Link elements, displaying a toggle button instead */}
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => {setShowPanel(!showPanel) }} on className="ms-auto" />
                {/* the offcanvas section defines what is hidden off canvas when their is not enough space to be displayed on screen */}
                
                <Navbar.Collapse className=" w-100 justify-content-end navbar-collapse-bg" style={{ 
                    marginTop:"50px"
                }}>
                    
                    {/*REMOVE ALL THE CLASSNAMES WHEN BANNER IS REMOVED */}
                    <Nav>

                        {/* <ConstructionBanner /> */}
                        
                        <Nav.Link onClick={() =>{navigate("/")}} 
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            className='desktopOptionsText' 
                            style={{
                            fontSize: "25px",
                            textDecoration: "none",
                            marginLeft: "5px",
                            marginRight: "5px",
                            whiteSpace: "nowrap",
                            color:"#FFAE46"
                        }}>Home
                        </Nav.Link>

                        <Nav.Link onClick={() =>{navigate("/login")}} 
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            className='desktopOptionsText' 
                            style={{
                            fontSize: "25px",
                            textDecoration: "none",
                            marginLeft: "5px",
                            marginRight: "5px",
                            whiteSpace: "nowrap",
                            color:"#FFAE46"
                        }}>Login
                        </Nav.Link>

                       <Nav.Link onClick={() =>{navigate("/faq")}} 
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            className='desktopOptionsText' 
                            style={{
                            fontSize: "25px",
                            textDecoration: "none",
                            marginLeft: "5px",
                            marginRight: "5px",
                            whiteSpace: "nowrap",
                            color:"#FFAE46"
                        }}>F.A.Q
                        </Nav.Link>

                        <Nav.Link onClick={() =>{navigate("/services")}} 
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            className='desktopOptionsText' 
                            style={{
                            fontSize: "25px",
                            textDecoration: "none",
                            marginLeft: "5px",
                            marginRight: "5px",
                            whiteSpace: "nowrap",
                            color:"#FFAE46"
                        }}>Services
                        </Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    ))}
        {!showPanel && <ConstructionBanner/> }
    </>
  )
}
