//importing axios to make fetch request
import axios from "axios";

//importing fetch requests to create normal and error logs
import { 
  createLog,
  createErrorLog
} from "../shared/loggingFunctions";

//setting the base endpoint to send fetch api's too
const API_URL = 'https://bike911-server.onrender.com/order';




//===================================CREATE WORK ORDER===================================//

//------FUNCTIONAL------//
export const createWorkOrderFetch = async (accessToken, locationSent) => {

  //data for api logging
  const endpoint = "/order/create-work-order"
  const method = "POST"

  //setting the header of fetch request to include access token
  const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
  };

  try{

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_body:locationSent
    })

      //sending fetch request
      const requestSent = await axios.post(`${API_URL}/create-work-order`,locationSent, {headers})

      //if checking for successful response
      if(requestSent.status === 201){
        const serverResponse = {
          data:requestSent.data.data.work_order_id,
          message:requestSent.data.message,
        }

        //CREATE SUCCESS LOG//
        await createLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH RESPONSE",
          response_data:serverResponse,
        })
  
        //returning data
        return serverResponse
      }
        
  }catch(error){

      //if checking that proper error data was recieved
      if (error.response && error.response.data) {
        const message = error.response.data.message
        const serverResponse = {
            message:message
        }

        //CREATE ERROR LOG//
        await createErrorLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH ERROR",
          error_data:serverResponse,
        })
      
        return serverResponse
      } 
  }
}











//===================================USER CONFIRMING SERVICE===================================//

//------FUNCTIONAL------//
export const sendUserConfirmService = async (accessToken, idWorkOrder) => {

    //data for api logging
    const endpoint = "/order/user-confirm-service"
    const method = "PATCH"

    //setting header of fetch request to include access token
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json' 
      };

    try{

      //CREATE INITIAL LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"SENDING API FETCH",
        request_query:idWorkOrder
      })

        //sending fetch request
        const requestSent = await axios.patch(`${API_URL}/user-confirm-service?id=${idWorkOrder}`, {},  {headers})

        //if checking for succesful response
        if(requestSent.status === 200){
          const serverResponse = {
            data:requestSent.status,
            message:requestSent.data.message,
          }
    
          //CREATE SUCCESS LOG//
          await createLog({
            endpoint:endpoint,
            method:method,
            type:"FETCH RESPONSE",
            response_data:serverResponse,
          })

          //returning data
          return serverResponse
        }
          
        }catch(error){

          //if checking that proper error data was recieved
          if (error.response && error.response.data) {
            const message = error.response.data.message
            const serverResponse = {
                message:message
            }

            //CREATE ERROR LOG//
            await createErrorLog({
              endpoint:endpoint,
              method:method,
              type:"FETCH ERROR",
              error_data:serverResponse,
            })

            return serverResponse
          } 
        }
}











//===================================DELTING A WORK ORDER===================================//

export const workOrderDelete = async (accessToken, idWorkOrder) => {

    //data for api logging
    const endpoint = "/order/delete-work-order"
    const method = "DELETE"

    //setting the header of the fetch request to include the access token
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json' 
    };
  
    try{ 

      //CREATE INITIAL LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"SENDING API FETCH",
        request_query:idWorkOrder
      })

      //sending fetch request
      const requestSent = await axios.delete(`${API_URL}/delete-work-order?id=${idWorkOrder}`, {headers})
  
      //if checking for successful server response
      if(requestSent.status === 200){
        const serverResponse = {
          data:requestSent.status,
          message:requestSent.data.message,
        }

        //CREATE SUCCESS LOG//
        await createLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH RESPONSE",
          response_data:serverResponse,
        })
  
        return serverResponse
      }

  
    }catch(error){

      //if ensuring that proper error data was recieved
      if (error.response && error.response.data) {
        const message = error.response.data.message
        const serverResponse = {
            message:message
        }

        //CREATE ERROR LOG//
        await createErrorLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH ERROR",
          error_data:serverResponse,
        })

        return serverResponse
      } 
    }
}
  







//===================================SENDING USER LOCATION TO DATABASE AND GETTING WORK ORDER===================================//

//------FUNCTIONAL------//
export const getUpdatedWorkOrder = async (accessToken, idWorkOrder, usersUpdatedLocation) => {
  
    //data for api logging
    const endpoint = "/order/user-updated-work-order"
    const method = "POST"

    //setting headers of fetch request to inlcude access token
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json' 
    };

    //formating location data to send
    const locationSent = {
        updateLat: usersUpdatedLocation.lat,
        updateLng: usersUpdatedLocation.lng,
    }

    try{ 

      //CREATE INITIAL LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"SENDING API FETCH",
        request_body:locationSent,
        request_query:idWorkOrder
      })


        //sending fetch request
        const requestSent = await axios.post(`${API_URL}/user-updated-work-order?id=${idWorkOrder}`, locationSent, {headers})

        //if checking that no mechanic has accepeted yet
        if(requestSent.data.data.time_stamp){
          const serverResponse = {
            time_stamp:requestSent.data.data,
            message:requestSent.data.message,
          }

          //CREATE SUCCESS LOG//
          await createLog({
            endpoint:endpoint,
            method:method,
            type:"FETCH RESPONSE",
            response_data:serverResponse,
          })
    
          return serverResponse
        }

        //if confirming successful response from the server
        if(requestSent.status === 200){
          const serverResponse = {
            data:requestSent.data.data,
            message:requestSent.data.message,
          }

          //CREATE SUCCESS LOG//
          await createLog({
            endpoint:endpoint,
            method:method,
            type:"FETCH RESPONSE",
            response_data:serverResponse,
          })
    
          //returning data
          return serverResponse
        }


    }catch(error){

        //if checking that proper error data was recieved
        if (error.response && error.response.data) {
          const message = error.response.data.message
          const serverResponse = {
              message:message
          }

          //CREATE ERROR LOG//
          await createErrorLog({
            endpoint:endpoint,
            method:method,
            type:"FETCH ERROR",
            error_data:serverResponse,
          })

          return serverResponse
        } 

    }
}









//===================================CUSTOMER MARKING A WORK ORDER AS COMPLETE===================================//

export const completeWorkOrderFetch = async (accessToken, customer_feedback) => {
  

  //data for api logging
  const endpoint = "/order/complete-work-order"
  const method = "POST"

  //setting header of fetch to include access token
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json' 
  };

  const requestBody = {
    user_review: customer_feedback
  }

  try{ 

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_body:requestBody,
    })

    //sending fetch
    const requestSent = await axios.post(`${API_URL}/complete-work-order`, requestBody, {headers})

    //if checking for succesful server response
    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.data.data,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      //returning data
      return serverResponse
    }


}catch(error){

    //if ensuring proper error data was recieved
    if (error.response && error.response.data) {
      const message = error.response.data.message
      const serverResponse = {
          message:message
      }

      //CREATE ERROR LOG//
      await createErrorLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH ERROR",
        error_data:serverResponse,
      })

      return serverResponse
    } 

}
}










//===================================CHECKING FOR WORK ORDER COMPLETION===================================//

export const checkCompleteWorkOrderFetch = async (accessToken) => {

  //data for api logging
  const endpoint = "/order/check-mechanic-complete"
  const method = "GET"

  //setting headers of fetch to include access token
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json' 
  };

  try{ 

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
    })

    //sending fetch
    const requestSent = await axios.get(`${API_URL}/check-mechanic-complete`, {headers})

    //checking for succesful server response
    if(requestSent.status === 200){
      const serverResponse = {
        data:requestSent.data.data,
        message:requestSent.data.message,
      }

      //CREATE SUCCESS LOG//
      await createLog({
        endpoint:endpoint,
        method:method,
        type:"FETCH RESPONSE",
        response_data:serverResponse,
      })

      //returning data
      return serverResponse
    }


  }catch(error){

      //if ensuring proper error data was recieved
      if (error.response && error.response.data) {
        const message = error.response.data.message
        const serverResponse = {
            message:message
        }

        //CREATE ERROR LOG//
        await createErrorLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH ERROR",
          error_data:serverResponse,
        })
        
        return serverResponse
      } 

  }

}


//===================================CREATE WORK ORDER===================================//

//------NOT FUNCTIONAL------//
export const getCurrentUserLocationFetch = async (accessToken, locationSent) => {

  //data for api logging
  const endpoint = "/order/get-user-location"
  const method = "POST"

  //setting the header of fetch request to include access token
  const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
  };

  try{

    //CREATE INITIAL LOG//
    await createLog({
      endpoint:endpoint,
      method:method,
      type:"SENDING API FETCH",
      request_body:locationSent
    })

      //sending fetch request
      const requestSent = await axios.post(`${API_URL}/get-user-location`,locationSent, {headers})

      console.log("API REQUEST SENT: ", requestSent)

      //if checking for successful response
      if(requestSent.status === 200){
        const serverResponse = {
          data:requestSent.data.data.location,
          message:requestSent.data.message,
        }

        //CREATE SUCCESS LOG//
        await createLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH RESPONSE",
          response_data:serverResponse,
        })
  
        //returning data
        return serverResponse
      }
        
  }catch(error){

      //if checking that proper error data was recieved
      if (error.response && error.response.data) {
        const message = error.response.data.message
        const serverResponse = {
            message:message
        }

        //CREATE ERROR LOG//
        await createErrorLog({
          endpoint:endpoint,
          method:method,
          type:"FETCH ERROR",
          error_data:serverResponse,
        })
      
        return serverResponse
      } 
  }
}






