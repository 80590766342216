import React from 'react'

import {
    handleMouseOut,
    handleMouseOutGreen,
    handleMouseOver
} from '../../../shared/sharedFunctions'

export default function OrderDetailsForm({
    updateForm,
    handleFileChange,
    workOrderSummary,
    proceedToSummary,
    returnToStart
}) {


    return (
        <div
        style={{
            justifyContent:"center",
            alignItems:"center",
            display:"flex",
            flexDirection:"column"
        }}>

            <p style={{
                color: "#4D8C53",
                fontWeight: "bold",
                animation: "fadeInUp 1s ease-in-out"
            }}>
                Please provide some more information about your order
            </p>

            <div className="form w-100 d-grid gap-3 p-4" style={{ maxWidth: "350px" }}>

                <div className="poppins-bold" style={{ color: "#FFAE46" }}>Description of work required</div>
                <div className="">
                    <input
                        id="name"
                        className="form-control"
                        type="text"
                        placeholder='Enter Description Here'
                        value={workOrderSummary.work_description}
                        onChange={(e) => updateForm({ work_description: e.target.value })}>
                    </input>
                </div>

                <div className="">
                    <label htmlFor="image" className="poppins-bold" style={{ color: "#FFAE46" }}>Photo upload</label>
                    <input
                        id="image"
                        className="form-control"
                        type="file"
                        accept="image/*" // Restricts the file selection to image types
                        onChange={(e) => handleFileChange(e.target.files[0])} // A function to handle the file upload
                    />
                </div>

                <button
                    className="btn"
                    style={{
                        backgroundColor: "#4D8C53",
                        color: "white",
                        border: "1px solid #4D8C53",
                        textShadow: "1px 1px 2px black",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                    }}
                    onClick={() => { proceedToSummary() }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOutGreen}
                    type="button">PROCEED TO SUMMARY
                </button>
                <button
                    className="btn"
                    style={{
                        backgroundColor: "#FFAE46",
                        color: "white",
                        border: "1px solid #FFAE46",
                        textShadow: "1px 1px 2px black",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                    }}
                    onClick={() => { returnToStart() }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    type="button">GO BACK
                </button>
            </div>
        </div>
    )
}
