import React from "react";


import background from "../../../images/palmtreesdark.png"

import PublicHeader from '../PublicHeader'

import ServiceCard from './ServiceCard'

import { cardData } from './CardContent'

export default function Services() {

  const createCards = () => {
  
    return cardData.map((card, index) => {

      return (
        <ServiceCard 
          key={index} 
          title={card.title} 
          text={card.text} 
          price={card.price} 
          memberPrice={card.member_price}
          image={card.image}
          imagePosition={card.image_position}
        />
      );
    });
  
  }

  //REDEFINE THE TEXT

  return (
    <div 
      className="splash-screen d-flex justify-content-center align-items-center" 
      style = {{backgroundImage:`url(${background})`}}>

      <PublicHeader/>

      <div style={{ 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center",
        height: "80%",
        width: "100%",
        overflowY: "auto", 
        paddingBottom: "20px",
        scrollbarWidth: "none",
        '-ms-overflow-style': 'none' }}> 

        {createCards()}

      </div>
      
        
                
                    
                    
    </div>
)

}
