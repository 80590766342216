import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    handleMouseOut,
    handleMouseOutGreen,
    handleMouseOver
} from '../../../shared/sharedFunctions'

export default function WorkRequestedForm({
    updateForm,
    confirmInitialDetails
}) {
    const navigate = useNavigate()

    //COST IS IN PENNIES AS THATS WHAT STRIPE REQUIRES
    const services = {
        help: {
            id: 1,
            description: "on site emergency repair",
        },
        general: {
            id: 2,
            description: "basic maintenance and upkeep",
        },
        assembly: {
            id: 3,
            description: "building new ebike",
        },
        brakes: {
            id: 4,
            description: "brake replacement",
        },
        diagnosis: {
            id: 5,
            description: "electric componenet diagnosis",
        },
        adjustment: {
            id: 6,
            description: "wheel maintenance and adjustment",
        },
        other: {
            id: 7,
            description: "any other service",
        }
    }

    const populateSelection = (id) => {
        const selectedService = Object.values(services).find(service => service.id === id);

        updateForm({work_request:selectedService})
    }

    return (
        <div>

            <p style={{
                color: "#4D8C53",
                fontWeight: "bold",
                animation: "fadeInUp 1s ease-in-out"
            }}>
                Please select what we can assist you with
            </p>
            <div
                className="form w-100 d-grid gap-3 p-4"
                style={{ maxWidth: "400px", textAlign: "left" }}
            >
                <div className="">
                    <label style={{ color: "white", fontWeight: "bold" }}>
                        <input
                            type="radio"
                            name="workRequest"
                            value={1}
                            onChange={() => populateSelection(1)}
                        />
                        Trailside/Roadside Assistance
                    </label>
                </div>
                <div className="">
                    <label style={{ color: "white", fontWeight: "bold" }}>
                        <input
                            type="radio"
                            name="workRequest"
                            value={2}
                            onChange={() => populateSelection(2)}
                        />
                        Tune-Up
                    </label>
                </div>
                <div className="">
                    <label style={{ color: "white", fontWeight: "bold" }}>
                        <input
                            type="radio"
                            name="workRequest"
                            value={3}
                            onChange={() => populateSelection(3)}
                        />
                        New Bike Assembly
                    </label>
                </div>
                <div className="">
                    <label style={{ color: "white", fontWeight: "bold" }}>
                        <input
                            type="radio"
                            name="workRequest"
                            value={4}
                            onChange={() => populateSelection(4)}
                        />
                        Brake Maintenance
                    </label>
                </div>
                <div className="">
                    <label style={{ color: "white", fontWeight: "bold" }}>
                        <input
                            type="radio"
                            name="workRequest"
                            value={5}
                            onChange={() => populateSelection(5)}
                        />
                        Electrical Issue
                    </label>
                </div>
                <div className="">
                    <label style={{ color: "white", fontWeight: "bold" }}>
                        <input
                            type="radio"
                            name="workRequest"
                            value={6}
                            onChange={() => populateSelection(6)}
                        />
                        Wheel Truing & Spoke Adjustment
                    </label>
                </div>
                <div className="">
                    <label style={{ color: "white", fontWeight: "bold" }}>
                        <input
                            type="radio"
                            name="workRequest"
                            value={7}
                            onChange={() => populateSelection(7)}
                        />
                        Other
                    </label>
                </div>
                <button
                    className="btn"
                    style={{
                        backgroundColor: "#4D8C53",
                        color: "white",
                        border: "1px solid #4D8C53",
                        textShadow: "1px 1px 2px black",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                    }}
                    onClick={() => { confirmInitialDetails() }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOutGreen}
                    type="button">MOVE TO ORDER DETAILS
                </button>
                <button
                    className="btn"
                    style={{
                        backgroundColor: "#FFAE46",
                        color: "white",
                        border: "1px solid #FFAE46",
                        textShadow: "1px 1px 2px black",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                    }}
                    onClick={() => { navigate("/home") }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    type="button">CANCEL
                </button>
            </div>
        </div>
    )
}
