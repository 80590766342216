import React from 'react'

import mapExample from "../../../images/landing/map-example.png"

export default function Reports() {
    return (
        <div style={{
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            flexDirection:"column",
            marginTop:"120px",
            }}>
        
            <div style={{
                // backgroundColor:'rgba(0, 0, 0, 0.5)',
                borderRadius: "20px", 
                display:'flex',
                width: "80%",  // Use a percentage for width
                maxWidth: "1800px",
                height: "auto", // Set height to auto for better responsiveness
                display: "flex", 
                flexDirection: "column", 
                justifyContent: "center",
                alignItems:"center",
                paddingBottom:"80px",
                paddingTop:"80px",
                }}>
    
    
                <header style={{color:"#FFAE46", fontSize:"2rem", fontWeight:"bold"}}>Real Time Mechanic Reports</header>

                <hr style={{ 
                    width: "80%", 
                    margin: "10px auto", 
                    borderTop: "1px solid white" 
                }} />

                <p style={{
                    color:"white",
                    fontSize:"1rem",
                    textAlign:"center",
                    paddingLeft: "10%",
                    paddingRight: "10%",}}>
                    On our map component you will be able to see all the nearby mechanic. 
                    After requesting a work order you can see your mechanic coming to your 
                    location in real time, with an accurate time to arrival
                </p>     
                <img 
                    src={mapExample} 
                    alt="Bike tools" 
                    style={{ 
                        height: "100%", 
                        width: "80%", 
                        objectFit: "cover",
                        borderRadius: "10px", 
                        maxHeight:"320px"
                    }} 
                />
            </div>
        </div>
      )
}
