import React from 'react'

import {data} from "./sectionData"
import AboutCard from './AboutCard'



export default function AboutUs() {

  const addInfo = () => {
    return data.map((person,index) => {

      return(
        <AboutCard
          key={index}
          name={person.name}
          name_link={person.name_link}
          title={person.title}
          image={person.image}
          description={person.description}
          imagePosition={person.image_position}
        />
      )

    })
  }

  return (
    <div style={{
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        paddingBottom: "40px",
        marginTop:"120px"}}>
    
        <div style={{
            // backgroundColor:'rgba(0, 0, 0, 0.5)',
            borderRadius: "10px", 
            display:'flex',
            width: "80%",  // Use a percentage for width
            maxWidth: "1800px",
            flexDirection:"column",
            padding:"10px",
            height: "auto", // Set height to auto for better responsiveness
            }}>

                <header style={{color:"#FFAE46", fontSize:"3rem", fontWeight:"bold"}}>About Us</header>

                <hr style={{ 
                    width: "80%", 
                    margin: "10px auto", 
                    borderTop: "1px solid white" 
                }} />

                {addInfo()}

            
        </div>
    </div>



  )
}
