import React, { useEffect, useState } from 'react';


export default function ServiceCard({title, text, price, memberPrice, image, imagePosition}) {

    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    useEffect(() => {
        handleResize(); // Check on initial render
        window.addEventListener('resize', handleResize); // Check on resize

        return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
    }, []);

  return (
    <div style={{
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        marginTop:"120px"}}>
            
        <div style={{
            backgroundColor:'rgba(0, 0, 0, 0.5)',
            borderRadius: "10px", 
            display:'flex',
            flexDirection: isMobile ? "column" : (imagePosition === "left" ? "row" : "row-reverse"),
            width: "90%",  // Use a percentage for width
            maxWidth: "1800px",
            height: "auto", // Set height to auto for better responsiveness
            }}>

            <div style={{flex:"1"}}>
                <img 
                    src={image} 
                    alt="Bike tools" 
                    style={{ 
                        height: "100%", 
                        width: "100%", 
                        objectFit: "cover",
                        borderRadius: "10px", 
                        maxHeight:"320px"
                    }} 
                />
            </div>
            
            <div style={{
                flex:"2", 
                display: "flex", 
                flexDirection: "column", 
                justifyContent: "center"}}>

                    <header style={{color:"#FFAE46", fontSize:"2rem", fontWeight:"bold"}}>{title}</header>

                    <hr style={{ 
                        width: "80%", 
                        margin: "10px auto", 
                        borderTop: "1px solid white" 
                    }} />

                    <p style={{color:"white",fontSize:"1rem",textAlign:"justify",paddingLeft: "30px",paddingRight: "30px",}}>
                        {text}
                    </p>

                    <hr style={{ 
                        width: "80%", 
                        margin: "5px auto", 
                        borderTop: "1px solid white" 
                    }} />

                    <header style={{color:"#4D8C53", fontSize:"1.5rem", marginTop:"10px"}}>{price}</header>
                    <header style={{color:"#4D8C53", fontSize:"1.5rem"}}>{memberPrice}</header>
                
            </div>


        </div>
    </div>
  )
}
