import React, {useState} from 'react'

//importing ability to navigate
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

//importing required images
import background from "../../../images/palmtreesdark.png";
import LoadingModal from '../../CustomerOnlySection/Modals/LoadingModal.jsx';

//importing styling functions for buttons
import {
  handleMouseOver,
  handleMouseOut,
  successToast,
  failToast
} from "../../../shared/sharedFunctions.js"

//importing fetch request to register account
import { registerFetch } from '../../../fetchRequests/loginFetch.js';

import PublicHeader from "../PublicHeader.jsx"

export default function Register() {

  //ability to navigate
  const navigate = useNavigate();


  //use state used for registeration form for new user
  const [registerForm, setRegisterForm] = useState(
      {
        name:null,
        user_name:null,
        phone:null,
        email:null,
        password:null,
        confirm_password:null,
        
        brand:null,
        model:null
        
      }
  )

  //use state determing wether loading modal is shown or not
  const [loading, setLoading] = useState(false)

  //function used to update form values
  const updateForm = (value) => {
    return setRegisterForm((prev) => {
      return {...prev, ...value}
    })
  }

//===================================SENDING USER REGISTER REQUEST===================================//

  const confirmSubmission = async (e) => {

    e.preventDefault();

    setLoading(true)

    if (!registerForm.name || !registerForm.user_name ||
        !registerForm.phone || !registerForm.email ||
        !registerForm.password || !registerForm.confirm_password) {
      failToast("All input fields are required");
      setLoading(false)
      return;
  }

    //defining a variable with values entered in the input fields of the HTML
    const newUser = {
        name: registerForm.name,
        user_name: registerForm.user_name,
        phone: registerForm.phone,
        email: registerForm.email,
        password: registerForm.password,
        confirm_password: registerForm.confirm_password
    };

    //calling function to send fetch request that will register a new user
    const registerAttempt = await registerFetch(newUser)

      setLoading(false)

      if(!registerAttempt.data){
        failToast(registerAttempt.message)
        return
      }

      setRegisterForm({
        name:"",
        user_name:"",
        phone:"",
        email:"",
        password:"",
        confirm_password:""
      });
      navigate("/register-code");
      successToast(registerAttempt.message)
      return
      
  };

//===================================JSX RETURN===================================//

  return (
    <div
      className="splash-screen d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="loginPageContent">

      <PublicHeader/>
      
        {loading ? (
          <div>
              <LoadingModal message={"Registering your account..."}/>
          </div>
            ) : (
          <>
            <form onSubmit={confirmSubmission} autoComplete="off" style={{width:"100%", maxWidth:"350px"}}>
              <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                <div>
                  <input
                    className="form-control"
                    placeholder="Enter Your Full Name"
                    value={registerForm.name}
                    required
                    onChange={(e) => updateForm({name: e.target.value})}
                  ></input>
                </div>
                <div>
                  <input
                    className="form-control"
                    placeholder="User Name"
                    value={registerForm.user_name}
                    required
                    onChange={(e) => updateForm({user_name: e.target.value})}
                  ></input>
                </div>
                <div>
                  <input
                    className="form-control"
                    placeholder="Mobile Phone (Numbers only)"
                    value={registerForm.phone}
                    required
                    onChange={(e) => updateForm({phone: e.target.value})}
                    pattern="\d*"
                  ></input>
                </div>
                <div>
                  <input
                    className="form-control"
                    placeholder="Email"
                    value={registerForm.email}
                    required
                    onChange={(e) => updateForm({email: e.target.value})}
                  ></input>
                </div>
                <div>
                  <input
                    className="form-control"
                    placeholder="Password"
                    type="password"
                    required
                    value={registerForm.password}
                    onChange={(e) => updateForm({password: e.target.value})}
                  ></input>
                </div>
                <div>
                  <input
                    className="form-control"
                    placeholder="Confirm Password"
                    type="password"
                    required
                    value={registerForm.confirm_password}
                    onChange={(e) => updateForm({confirm_password: e.target.value})}
                  ></input>
                </div>
                <div>
                  <input
                    className="form-control"
                    placeholder="Bike Brand"
                    value={registerForm.brand}
                    onChange={(e) => updateForm({brand: e.target.value})}
                  ></input>
                </div>
                <div>
                  <input
                    className="form-control"
                    placeholder="Bike Model"
                    value={registerForm.model}
                    onChange={(e) => updateForm({model: e.target.value})}
                  ></input>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  Register
                </button>
              
                <div className="text-center">
                  <p className="registerText poppins-medium">
                    Already have an account?
                  </p>
                  <Link to="/login" className="registerText poppins-medium">
                    Log In
                  </Link>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
