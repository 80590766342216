import React from 'react'

export default function QuestionAnswerLines({question, answer}) {
  return (
    <div style={{width:"75%"}}>
        <p style={{color: "#FFAE46"}}> Q: {question} </p>
        <p style={{color: "#4D8C53"}}> A: {answer} </p>

        <hr style={{ 
            width: "80%", 
            margin: "10px auto", 
            borderTop: "1px solid white" 
        }} />

    </div>
  )
}
