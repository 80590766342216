import React from 'react'
import Logo from '../../Logo'

export default function LandingFooter() {

  return (
    <footer style={{
        width: '100%',
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '10px',
        color: 'white',
      }}>

        <div style={{
        display:"flex",
        width: '100%',
        zIndex: 1000,
        textAlign:"justify",
        justifyContent:"center"
        }}>
            <div style={{
                textAlign:"justify"}}>
                <h5 style={{color:"#FFAE46"}}>
                    Contact Information
                </h5>
                <p style={{color:"#4D8C53"}}>Phone Number: {' '}
                    <a href='tel:7275658775' style={{color:"white", textDecoration:"none"}}>
                        727-565-8775
                    </a>
                </p>
                <p style={{color:"#4D8C53"}}>Email: {' '}
                    <a href='mailto:bike911org@gmail.com' style={{color:"white", textDecoration:"none"}}>
                        bike911org@gmail.com
                    </a>
                </p>
                <p style={{color:"#4D8C53"}}>Mailing Address: {' '}
                    <a style={{color:"white", textDecoration:"none"}}>
                        Coming Soon
                    </a>
                </p>
            </div>
        </div>


        <span style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>

            <Logo style={{width: '30px'}}/>

            <p style={{
                marginLeft:"5px", 
                marginRight:"5px", 
                color:"#FFAE46",
                marginBottom:"0px",
                fontSize:"15px"}}>
                Site Maintained by: {' '}
                <a href='https://www.blackburg.biz/' style={{color:"#4D8C53"}}>
                    Blackburg.biz
                </a>
            </p>
            <p style={{
                marginLeft:"5px", 
                marginRight:"5px", 
                color:"#FFAE46",
                marginBottom:"0px",
                fontSize:"15px"}}>
                Web Master: {' '}
                <a href='mailto: silverdaax@gmail.com' style={{color:"#4D8C53"}}>
                    Max Wolfe
                </a>
            </p>
        </span>

        <span style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>

            <a 
                href='privacyPolicy.html' 
                style={{color:"#4D8C53"}}
                target='_blank'
            >
                Privacy Policy
            </a>
            
        </span>
      
    </footer>
  )
}
