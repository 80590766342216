import React from 'react'

import background from "../../../images/palmtreesdark.png"

import PublicHeader from '../PublicHeader'

import ParallaxSection from './Parallax'

import TestVideo from './VideoComponents/TestVideo'

import Reports from './Reports'

import ServiceRibbon from './ServiceRibbon'

import AboutUs from '../AboutUsComponents/AboutUs'

import LandingFooter from './LandingFooter'


export default function Landing() {


    

    return (
        
        <div 
            className="splash-screen d-flex justify-content-center align-items-center" 
            style={{ 
                backgroundImage: `url(${background})`, 
                minHeight: "100vh"
              }}>
    
          <PublicHeader/>

          <div
            style={{
                height: "100%",
                width: "100%",
                overflowY: "auto", 
                scrollbarWidth: "none",
                '-ms-overflow-style': 'none'
              }}
            >

            <ParallaxSection/>

            <TestVideo/>

            {/* <OurMission/> */}
            
            <Reports/>

            <ServiceRibbon/>

            <AboutUs/>

            <LandingFooter/>

          </div>
              
                        
        </div>
    )
}
