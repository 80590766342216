import React, { useState } from 'react'
import background from "../../../images/palmtreesdark.png"
import Logo from '../../Logo';

import ConfirmLocationModal from './ConfirmLocationModal';

import WorkRequestedForm from './WorkRequestedForm';
import OrderDetailsForm from './OrderDetailsForm';
import SummaryConfirm from './SummaryConfirm';

export default function NewWorkOrder({
    usersUpdatedLocation
}) {

    //use state to determine wether or not to show the loading modal
    const [loading, setLoading] = useState(false)

    const [showLocationModal,setShowLocationModal] = useState(true)

    const [userLocationString, setUserLocationString] = useState(null)

    //==================USE STATES HOLDING WHICH STAGE OF THE FORM TO BE ON==================//

    const [getInitialInfo, setGetInitialInfo] = useState(true)
    const [getDetails, setGetDetails] = useState(false)
    const [presentSummary, setPresentSummary] = useState(false)

    //==================COLLECTING DATA FROM USER==================//

    

    const [workOrderSummary, setWorkOrderSummary] = useState({
        user_location: "",
        work_request: "",
        work_description: "",
        work_order_image: "",
        updateLat: usersUpdatedLocation.lat,
        updateLng: usersUpdatedLocation.lng,
    })


    //==================FUNCTIONS TO UPDATE THE WORK ORDER DETAILS FORM==================//

    const updateForm = (value) => {
        return setWorkOrderSummary((prev) => {
            return { ...prev, ...value }
        })
    }

    const handleFileChange = (file) => {
        console.log(file)
        updateForm({ work_order_image: file });

    };


    //==================FUNCTIONS TO SHIFT TO DIFFERENT STAGES OF FORM==================//

    const confirmInitialDetails = () => {
        setGetInitialInfo(false)
        setGetDetails(true)
        setPresentSummary(false)
    }

    const proceedToSummary = () => {
        setGetInitialInfo(false)
        setGetDetails(false)
        setPresentSummary(true)
    }

    const returnToStart = () => {
        setGetInitialInfo(true)
        setGetDetails(false)
        setPresentSummary(false)
    }

    return (
        <div
            className="splash-screen d-flex justify-content-center align-items-center"
            style={{
                backgroundImage: `url(${background})`,
                minHeight: "100vh"
            }}>

            <ConfirmLocationModal
                usersUpdatedLocation={usersUpdatedLocation}
                showLocationModal={showLocationModal}
                setShowLocationModal={setShowLocationModal}
                updateForm={updateForm}
            />


            <div
                style={{
                    height: "100%",
                    width: "100%",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                    '-ms-overflow-style': 'none',
                    display: "flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >

                <Logo style={{ width: "140px", marginBottom: "10px" }} />
                <h2 style={{
                    color: "#FFAE46",
                    fontWeight: "bold",
                    animation: "fadeInUp 1s ease-in-out"
                }}>
                    Creating A New Work Order
                </h2>

                <hr style={{
                    width: "70%",
                    margin: "10px auto",
                    borderTop: "1px solid white"
                }} />

                {getInitialInfo &&
                    <WorkRequestedForm
                        updateForm ={updateForm}
                        confirmInitialDetails={confirmInitialDetails}
                    />
                }

                {getDetails &&
                    <OrderDetailsForm
                        updateForm={updateForm}
                        handleFileChange={handleFileChange}
                        workOrderSummary={workOrderSummary}
                        proceedToSummary={proceedToSummary}
                        returnToStart={returnToStart}
                    />
                }

                {presentSummary &&
                    <SummaryConfirm
                        workOrderSummary={workOrderSummary}
                        returnToStart={returnToStart}
                        setLoading={setLoading}
                    />
                }

            </div>
        </div>
    );
}
