import React, {useState,useEffect} from 'react'
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
} from '@stripe/react-stripe-js';

import CheckoutForm from './CheckoutForm';
import background from "../../../images/palmtreesdark.png"
import { BIKE_LOGO_BLACK_BKG } from '../../../S3Images';
import axios from "axios";

export default function PaymentComponent() {
  const totalCost = 5000
  const test_card_number = "4242 4242 4242 4242"

  //Use state holding status of establishing connection to stripe
  const [stripePromise,setStripePromise] = useState(null)

  // holds the client secret from server side, which contains payment details
  //that are required to confirm the payment on the client side, while being secure
  //and not exposing the client or server key
  const [clientSecret, setClientSecret] = useState("");

  //===================================INITIALIZING THE STRIPE SESSION===================================//
  
  //fetching the public key from the server side which will be used
  //to create a new stripe session on the client side
  const fetchPublicKey = async () => {
    const fetchKey = await axios.get("https://bike911-server.onrender.com/payment/config")
    //loading the stripe session using the public key
    //once this is established the component will render
    setStripePromise(loadStripe(fetchKey.data.data))
  }

  //establishing an intent with the server, establishing details
  //about the payment that will be stored in a secret key
  //and passed to stripe for processing
  const fetchPaymentIntent = async () => {
    const fetchIntent = await axios.post("https://bike911-server.onrender.com/payment/intent")
    setClientSecret(fetchIntent.data.data)
  }

  //calling both initializing functions for stripe on page load
  useEffect(()=>{
    fetchPublicKey()
    fetchPaymentIntent()
  },[])


  //===================================SETTING APPEARANCE OPTIONS OF THE STRIPE FORM===================================//

  const stipeFormAppearance ={
    theme: 'night',
      variables: {
        colorText: '#ffffff',
      },
  }

  //===================================JSX RETURN===================================//
  return (
    <div
      className="splash-screen d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${background})`,
        minHeight: "100vh"
      }}>


      <div
        style={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
          scrollbarWidth: "none",
          '-ms-overflow-style': 'none',
          display:"flex",
          justifyContent:"center",
          alignItems:"center"
        }}
      >
        {stripePromise && clientSecret && (
          // Elements wrapper provides context to the stripe form
          //its appearance, what payment it is processing etc.
          <Elements 
            stripe={stripePromise} 
            options={{ 
              clientSecret:clientSecret, 
              appearance:stipeFormAppearance
              }}>
            <CheckoutForm />
          </Elements>
        )}

      </div>


    </div>
  );
}
