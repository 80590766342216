//importing react functionality
import React from 'react'

//importing required images
import baseUserIcon from "../../../images/user-icon.png"
// import heartIcon from "../../../images/svg/icn_favorite-on.svg"
// import greyHeartIcon from "../../../images/svg/icn_favorite-off.svg"

//sliding order panel on bottom of map component once there is an active work order
//non functional pieces are commented out
export default function OrderDetailsPanel({workOrderData}) {
    // const [slideUp, setSlideUp] = useState(false)

  return (
    <div className='fixed-bottom d-flex flex-column justify-content-center align-items-center w-100 pb-5 z-2' 
    style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
    >
            {/* <button 
                className='orderDetailsExpandButton'
                onClick={() => {setSlideUp(!slideUp)}}>
            </button> */}
            <div className='w-100 d-flex justify-content-center align-content-center px-4 py-2 text-left'>
                <img className="avatar avatar-48 bg-dark bg-opacity-50 rounded-circle text-white p-1"
                     src={baseUserIcon}
                     alt='User Icon'  />
                <div className='w-10 px-3'>
                    {/*I kept those two class cause they seems to be triggered to a dynamic call*/}
                    <div className='orderDetailsMechanicText' style={{color:"white"}}> <strong style={{color:"#FFAE46"}}>Your Mechanic:</strong> {workOrderData.mechanic_name}</div>
                    <div className="modalBodyRows">
                        <div className="confirmModalBodyLeftColumn poppins-regular" style={{color:"white"}}>
                            <strong style={{color:"#4D8C53"}}>Phone:</strong> {workOrderData.mechanic_phone}
                            <strong style={{color:"#4D8C53"}}>Time:</strong> {workOrderData.travel_time}
                        </div>
                    </div>
                </div>
            </div>
            {/* section that is intially hidden that will be shown when user hits the slide up button */}
            {/* {slideUp && (
            <div>
                <div className='orderDetailsCardsContainer'>
                    <button className='orderDetailsCards poppins-semibold'>Buy a Plan
                    </button>
                    <button className='orderDetailsCards poppins-semibold'>Get a Job
                    </button>
                    <button className='orderDetailsCards poppins-semibold'>F.A.Q
                    </button>
                </div>
            </div>
            )} */}
      </div>
  )
}