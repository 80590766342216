import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  handleMouseOver,
  handleMouseOutGreen,
  successToast,
  failToast
} from "../../../shared/sharedFunctions"
import { BIKE_LOGO_BLACK_BKG } from '../../../S3Images';
import Logo from "../../Logo";
import { width } from "@fortawesome/free-regular-svg-icons/faAddressBook";

export default function CheckoutForm() {

  //Establishing the stripe instance used for payment processing
  const stripe = useStripe();

  //Getting access to individual stripe assets such as
  //the PaymentElement
  const elements = useElements();

  //Holding an error or sucess message to display to the user
  //after the stripe payment is attempted
  const [message, setMessage] = useState(null);

  //State holding the status of payment processing
  const [isProcessing, setIsProcessing] = useState(false);


  //===================================SUBMITTING PAYMENT TO STRIPE===================================//

  const handleSubmit = async (e) => {

    //preventing default browser behavior for form submission
    e.preventDefault();

    //Ensuring that stripe and elements have loaded before moving to submission
    if (!stripe || !elements) {
      return;
    }

    //Setting processing to true to prevent the user from hitting the submit button again
    setIsProcessing(true);

    //Sending all the payment details to stripe to await a confirmation or error

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `https://bike911.org/payment-complete`,
      },
    });

    //behavior executed if an error is recieved
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      failToast(error.message)
    } else {
      setMessage("An unexpected error occured.");
      failToast("An unexpected error occured.")
    }

    //setting processing to false that will allow the form to be interacted with again
    setIsProcessing(false);
  };


  const paymentElementOptions = {
    layout: 'tabs',
    paymentMethodOrder: ['card']
  };


  //===================================JSX RETURN===================================//

  return (
    <div style={{
      display:"flex", 
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center"}}>
      <Logo style={{width:"140px", marginBottom:"10px"}}/>
      <h2 style={{ 
        color: "#FFAE46",
        fontWeight:"bold",
        animation: "fadeInUp 1s ease-in-out"
        }}>
          Thank you for choosing Bike911
      </h2>
      <p style={{ 
        color: "#4D8C53",
        fontWeight:"bold",
        animation: "fadeInUp 1s ease-in-out"
        }}>
          Please enter payment information below
      </p>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement 
          id="payment-element"
          options={paymentElementOptions} 
        />

        <button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          className="btn"
          style={{
            backgroundColor:"#4D8C53",
            animation: "fadeInUp 1s ease-in-out",
            marginTop:"30px",
            color:"white",
            border:"1px solid #4D8C53"
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOutGreen}
          >{isProcessing ? "Processing ... " : "SUBMIT PAYMENT"}
        </button>
        
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
      
  );
}