import React from 'react'
import YouTube from 'react-youtube'

export default function VideoComponent({videoId, videoOptions}) {

  return (

    <YouTube
        //The id of the video from youtube that you are rendering
        videoId={videoId}
        //the HTML id you want to assign to the video
        id={null}
        //the HTML classname you want to assign to the video
        className={"video-responsive"}
        //Setting the title attribute of the iframe
        title={"Hello"}
        //Options for displaying the iframe
        opts={videoOptions}
        //Setting an on error event handler for the iframe
        onError={(event) => console.error('Error occurred with youtube iframe', event)} // defaults -> noop
    />
    
  )
}



//ALL THE POSSIBLE PROPS FOR EMBEDDING A VIDEO


// <YouTube
//    videoId={string} // defaults -> null
//    id={string} // defaults -> null
//    className={string} // defaults -> null
//    containerClassName={string} // defaults -> ''
//    title={string} // defaults -> null
//    opts={obj} // defaults -> {}
//    onReady={func} // defaults -> noop
//    onPlay={func} // defaults -> noop
//    onPause={func} // defaults -> noop
//    onEnd={func} // defaults -> noop
//    onError={func} // defaults -> noop
//    onStateChange={func} // defaults -> noop
//    onPlaybackRateChange={func} // defaults -> noop
//    onPlaybackQualityChange={func} // defaults -> noop
// />;
