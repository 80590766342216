import React, {useState} from "react";

//importing ability to navigate
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

//importing necessary images
import background from "../../../images/palmtreesdark.png";

//importing styling functions for buttons
import {
  handleMouseOver,
  handleMouseOut,
  successToast,
  failToast
} from "../../../shared/sharedFunctions.js"

//importing fetch request which will send registration code
import { submitRegisterCode } from '../../../fetchRequests/loginFetch.js';

import PublicHeader from "../PublicHeader.jsx"

export default function MobileRegisterCode() {

  //ability to navigate
  const navigate = useNavigate();

  //use state holding the input field for the registration code
  const [inputFields, setInputFields] = useState({code:""})

  //function used to update the input field use state
  const updateForm = (value) => {
    return setInputFields((prev) => {
      return {...prev, ...value}
    })
  }

  const confirmSubmission = async (e) =>{

    e.preventDefault();
  
    //calling function which will send fetch request that contains the users registration code
    const sendRegisterCode = await submitRegisterCode(inputFields.code);

    if(!sendRegisterCode.data){
      failToast(sendRegisterCode.message)
      return
    }
    
    successToast(sendRegisterCode.data.message)
    navigate("/login")
    return
   

  }

//===================================JSX RETURN===================================//

  return (
    <div
      className="splash-screen d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="loginPageContent">

      <PublicHeader/>
      
      <form onSubmit={confirmSubmission} autoComplete="off" style={{width:"100%", maxWidth:"350px"}}>

        <div className="form w-100 d-grid gap-3" style={{maxWidth:"350px"}}>
          <div className="form d-flex justify-content-between gap-1 py-1">
            <input
              id="code"
              className="form-control poppins-regular"
              type="text"
              placeholder='000000'
              value={inputFields.code}
              onChange={(e) => updateForm({code:e.target.value})}
              required
              maxLength={6}
              style={{textAlign:"center"}}
              >
            </input>
          </div>
          {/* <div className="text-center">
              <p className="registerText poppins-regular">
                Haven't recieved the code,{" "}
                <Link to="/" className="registerText poppins-medium">
                  resend it.
                </Link>
              </p>
          </div> */}
            <button
              type="submit"
              className="btn btn-primary"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              Submit Code
            </button>
          </div>
        </form>
        <div className="text-center">
            <p className="registerText poppins-medium">
              Already have an account?
            </p>
            <Link to="/login" className="registerText poppins-medium">
              Log In
            </Link>
        </div>
      </div>
    </div>
  );
}
