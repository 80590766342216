import React from 'react'
import VideoComponent from './VideoComponent';

export default function TestVideo() {

    const videoId = "K6_RAEVPpjM"

    //The video options that are passed to the Youtube Iframe
    const videoOptions = {
        height: '100%',
        width: '100%',
        playerVars: {
            //Setting wether the video will autostart on page load, recieves 1 for true, 0 for false
            autoplay: 1,
            //Setting wether the iframe will have control options for the user
            //0 hide player controller
            //1 show player controlls
            //2 only show the play/pause buttons
            controls: 1, // Hides player controls
            //setting wether the player should show related videos after it is complete
            //0 do not show related videos
            //1 show related videos
            rel: 0, // Prevents related videos at the end
            //sets wether to show the youtube logo in the player or not
            //0 show youtube logo
            //1 hide the youtube logo
            modestbranding:1,
            //Sets the video ratio for mobile devices
            playsinline: 1,
            //sets wether the embedded video will loop or not
            //0 video does not loop
            //1 video will loop, you must set the playlist parameter to the video id
            loop: 1, 
            playlist: videoId,
            //Sets wether the video starts as muted on page load
            //0 video is unmuted
            //1 video audio is muted on load
            mute: 1,
        },
      };

  return (
    <div style={{
        // backgroundColor:'rgba(0, 0, 0, 0.5)',
        borderRadius: "10px",
        display: 'flex',
        width:"100%",
        height:"auto",
        justifyContent: 'center', // To center the iframe inside the div
        alignItems: 'center' // To vertically align the iframe
        }}>

            <VideoComponent 
                videoId={videoId} 
                videoOptions={videoOptions}
            />
      
    </div>
  )
}
