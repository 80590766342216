import React, {useState, useEffect} from 'react'

export default function AboutCard({
    name,
    name_link,
    title,
    image,
    description,
    imagePosition}) {

    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    useEffect(() => {
        handleResize(); // Check on initial render
        window.addEventListener('resize', handleResize); // Check on resize

        return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
    }, []);


  return (
    <div style={{
        display:'flex', 
        marginTop:"40px",
        flexDirection: isMobile ? "column" : (imagePosition === "left" ? "row" : "row-reverse"),
    }}>

        <div style={{flex:"1"}}>
            <img 
                src={image} 
                alt="Bike tools" 
                style={{ 
                    height: "100%", 
                    width: "100%", 
                    objectFit: "cover",
                    borderRadius: "10px", 
                    maxHeight:"320px"
                }} 
            />
        </div>

        <div style={{
            flex:"2", 
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center"}}>

            <header style={{
                color:"#FFAE46", 
                fontSize:"1.5rem", 
                fontWeight:"bold"}}>
                    {name_link ? (
                        <a style={{
                            color:"#FFAE46"
                            }} 
                            href={name_link}>
                            {name}
                        </a>
                    ) : ( 
                        name
                    )}
                    
            </header>
            <header style={{color:"#4D8C53", fontSize:"1rem"}}>{title}</header>


            <hr style={{ 
                width: "80%", 
                margin: "10px auto", 
                borderTop: "1px solid white" 
            }} />

            <p style={{
                color:"white",
                fontSize:"1rem",
                textAlign:"justify",
                paddingLeft: "10%",
                paddingRight: "10%",}}>
                {description}
            </p>   
        </div>

    </div>
  )
}
