import React, { useState, useEffect}from "react";

//importing react bootstrap styling components
import Modal from "react-bootstrap/Modal";
import Spinner from 'react-bootstrap/Spinner';

//importing ability to use cookies
import { useCookies } from "react-cookie";

//importing blank user icon image
import baseUserIcon from "../../../images/user-icon.png";


//importing styling functions for buttons
import {
    handleMouseOver,
    handleMouseOutGreen,
    failToast,
    successToast
} from "../../../shared/sharedFunctions"

//importing fetch requests
import { 
  completeWorkOrderFetch, 
  checkCompleteWorkOrderFetch 
} from "../../../fetchRequests/workOrderFetches";

export default function MechanicArriveModal({
  showMechanicArriveModal,
  setShowMechanicArriveModal,
  workOrderData,
  endOrder
}) {

  //ability to use browser cookies
  const [cookie, setCookie, removeCookie] = useCookies();

  //use state to determing wether to show or hide loading modal
  const [loading, setLoading] = useState(false)

  //use state holding wether mechanic has completed work order or not
  const [mechanicCompleteWorkOrder, setMechanicCompleteWorkOrder] = useState(false)

  //use state holding wether user has marked work order as complete
  const [confirmWorkComplete, setConfirmWorkComplete] = useState(false)

  //use state holding user feedback about work order
  const [orderFeedback, setOrderFeedback] = useState("")



  //===================================USER CONFIRMING WORK ORDER IS COMPLETE===================================//

  const completeWorkOrder = async () => {

    setLoading(true)

    const customer_feedback = orderFeedback
      
      //calling function to send fetch request that will let user mark work order as complete
      const serverResponse = await completeWorkOrderFetch(cookie.accessToken, customer_feedback)
            
      setLoading(false)

      if(!serverResponse.data){
        failToast(serverResponse.message)
        return
      }

      setOrderFeedback("")

      successToast(serverResponse.message)
      endOrder()
      setShowMechanicArriveModal(false)
      return
      };

      //setting order feedback use state
      const handleInputChange = (e) => {
        setOrderFeedback(e);
      };



  //===================================CHECKING FOR MECHANIC FINISHING ORDER===================================//
        
      const checkOrderComplete = async () => {

        setLoading(true)
      
        //sending fetch request on time looking for if mechanic has marked work order as complete
        const serverResponse = await checkCompleteWorkOrderFetch(cookie.accessToken)

        setLoading(false)

        if(!serverResponse.data){
            // failToast(serverResponse.message)
            return
        }

        successToast(serverResponse.message)
        setMechanicCompleteWorkOrder(true)
      }


      //use effect calling to check if order is complete by mechanic on 30 second timer
      useEffect(() => {
        let interval
        
        interval = setInterval(() => {
          if(!mechanicCompleteWorkOrder){
            if(showMechanicArriveModal){
              checkOrderComplete();
            }
          }
        }, 30000); // 30 seconds in milliseconds
          return () => clearInterval(interval);
      }, [mechanicCompleteWorkOrder, showMechanicArriveModal]);


      //use effect calling to check if order is completed by
      //mechanic on component load
      useEffect(() => {
            if(showMechanicArriveModal){
              checkOrderComplete();
            }
      }, [showMechanicArriveModal]);


  //===================================JSX RETURN===================================//

  return (
    <>
      <Modal
        size="sm-8"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showMechanicArriveModal}
        backdrop={false}
        keyboard={false}
        style={{
          maxWidth: "500px",
          maxHeight: "400px",
          top: '0', 
          left: '0', 
          position: 'fixed', 
          marginTop: '90px',
          marginLeft:"50px",
          overflow:"hidden"
        }}
      >
            <>

            {confirmWorkComplete ? (
                        <>
                            <Modal.Header className="modalHeader">
                                <div style={{textAlign:"left"}}>
                                    <Modal.Title 
                                        className="poppins-extrabold"
                                        style={{
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            textAlign:"center",
                                            color:"#FFAE46"
                                          }}
                                        >WORK ORDER IS COMPLETE</Modal.Title>
                                </div>                                
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    textAlign:"center",
                                    marginBottom:"10px"}}>
                                    <div className="form w-100 d-grid gap-3 p-4" style={{maxWidth:"350px"}}>
                                    <div className="poppins-bold">Please leave us any feedback about your order</div>
                                    <div className="">
                                        <input
                                            id="model"
                                            className={`form-control`}
                                            type="text"
                                            placeholder=''
                                            value={orderFeedback}
                                            onChange={(e) => handleInputChange(e.target.value)}
                                            />
                                    </div>
                                        <button
                                        className="btn"
                                        style={{
                                          backgroundColor:"#4D8C53",
                                          border:"1px solid #4D8C53"
                                        }}
                                        onClick={() => {completeWorkOrder()}}
                                        onMouseOver={handleMouseOver}
                                        onMouseOut={handleMouseOutGreen}
                                        type="button">Submit Information
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </>
            ) : (
                <>
                    <Modal.Header className="modalHeader" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Modal.Title 
                          className="poppins-extrabold" 
                          style={{ textAlign: 'center',color:"#FFAE46" }}>
                            YOUR MECHANIC HAS ARRIVED
                            {loading && (
                              <Spinner 
                              animation="border" 
                              role="status"
                              style={{
                                width:"16px",
                                height:"16px"
                              }}
                              />
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            style={{
                            display: "flex",
                            flexDirection: "column", // Arrange items in a column
                            alignItems: "center",
                            textAlign: "center",
                            marginBottom: "10px",
                            }}
                        >
                            <div className="iconAndUsernameField" style={{ width: "100%", display: "flex", alignItems: "center", marginBottom: "5px", marginLeft:"20px", marginRight:"20px" }}>
                            <img
                                src={baseUserIcon}
                                className="accountUserIcon"
                                alt="user icon"
                                style={{
                                flex: "0 0 auto",
                                marginRight: "10px",
                                width: "50px", // Fixed width for icon
                                height: "50px", // Fixed height for icon
                                borderRadius: "50%", // Ensures the image has a circular shape
                                objectFit: "cover" // Ensures the image retains its proportions and covers the circle
                                }}
                            />
                            <div className="userNameContainer" style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", whiteSpace: "nowrap" }}>
                                <div 
                                className="accountUserName poppins-medium" 
                                style={{ 
                                  color: "white", 
                                  overflow: "hidden", 
                                  textOverflow: "ellipsis", 
                                  fontSize: "18px" 
                                  }}>
                                <strong style={{color:"#4D8C53"}}>Mechanic:</strong>{" "}{workOrderData.mechanic_name}
                                </div>
                                <div 
                                className="accountUserName poppins-medium" 
                                style={{ 
                                  color: "white", 
                                  overflow: "hidden", 
                                  textOverflow: "ellipsis", 
                                  fontSize: "14px" 
                                  }}>
                                <strong style={{color:"#4D8C53"}}>Email:</strong>{" "}{workOrderData.mechanic_email}
                                </div>
                            </div>
                            </div>
                            <div className="form w-100 d-grid gap-3 p-4" style={{ maxWidth: "350px" }}>
                            {mechanicCompleteWorkOrder && (
                                <button
                                className="btn"
                                style={{
                                  backgroundColor:"#4D8C53",
                                  border:"1px solid #4D8C53"
                                }}
                                onClick={() => { setConfirmWorkComplete(true); }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOutGreen}
                                type="button"
                                >
                                Work is Complete
                                </button>
                            )}
                            </div>
                        </div>
                    </Modal.Body>
                </>

                )}
            </>
      </Modal>
    </>
  );
}
