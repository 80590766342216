import React from 'react'

import background from "../../../images/palmtreesdark.png"

import PublicHeader from '../PublicHeader'

import QuestionAnswerLines from './QuestionAnswerLines'

import { FAQData } from './FAQData';

export default function FAQPage() {

    const populateSection = () => {

        return FAQData.map((line, index) => {

            return (
            <QuestionAnswerLines
                key={index} 
                question={line.question}
                answer={line.answer}
            />
            );
        });
    }


  return (

    <div 
        className="splash-screen d-flex justify-content-center align-items-center" 
        style={{ 
            backgroundImage: `url(${background})`, 
            minHeight: "100vh"
            }}>

        <PublicHeader/>

        <div
        style={{
            marginTop:"150px",
            backgroundColor:'rgba(0, 0, 0, 0.5)',
            borderRadius: "20px", 
            height: "75%",
            width: "80%",
            overflowY: "auto", 
            padding: "10px",
            scrollbarWidth: "none",
            '-ms-overflow-style': 'none'
            }}
        >

            <header style={{color:"#FFAE46", fontSize:"2rem", fontWeight:"bold"}}>FREQUENTLY ASKED QUESTIONS</header>

            <hr style={{ 
                width: "80%", 
                margin: "10px auto", 
                borderTop: "3px solid white", 
                marginBottom:"20px"
            }} />

            <div style={{ 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center",
            height: "80%",
            width: "100%",
            overflowY: "auto", 
            paddingBottom: "20px",
            scrollbarWidth: "none",
            '-ms-overflow-style': 'none' }}> 

            {populateSection()}

            </div>

        </div>             
                    
    </div>



  )
}
