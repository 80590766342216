import React from 'react'

import background from "../../../images/landing/st-pete-skyline.jpg"
import { useNavigate } from "react-router";

import {
  handleMouseOver,
  handleMouseOut,
} from "../../../shared/sharedFunctions"

export default function ServiceRibbon() {

  const navigate = useNavigate();


  return (
        <section class="parallax parallax-1" style={{ 
            backgroundImage: `url(${background})`,
            minHeight: "35vh", // Use a minimum height for better flexibility
            height: "200px", // Let the section height adjust automatically
            backgroundSize: "cover",
            backgroundPosition: "center",
            alignItems:'center',
            justifyContent:"center",
            marginTop:"120px",
            paddingTop:"80px",
            WebkitMaskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%)",
            maskImage: "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%)"        
          }}>
            <div className="overlay dark-8" style={{
            position: "absolute", // Position overlay absolutely
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Adjust opacity as needed
            zIndex: 1 // Ensure overlay is above the background
            }}>
            </div>

              <div class="container" style={{marginTop:"15px"}}>

                  <div class="text-center">
                      <p class="fs-40 fw-300" style={{
                        color:"white",
                        fontSize:"1.5rem",
                        textAlign:"center",
                        paddingLeft: "10%",
                        paddingRight: "10%",
                        fontWeight:"bold"}}>
                          Serving the St.Petersburg Florida area <br/> Tuesday - Saturday (10AM-6PM)
                      </p>

                      <button
                        className="btn"
                        style={{
                          backgroundColor:"#4D8C53"
                        }}
                        onClick={() => {navigate("/services")}}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        >Our Services
                      </button>
                  </div>

              </div>

        </section>
  )
}
